<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-alert variant="danger" :show="errorPage">
      <h4 class="alert-heading">
        Error fetching publish vehicle data
      </h4>
      <div class="alert-body">
        No item found with this item id. Check
        <b-link class="alert-link" :to="{ name: 'inventory-publishedit' }">
          Inventory
        </b-link>
        for other items.
      </div>
    </b-alert>

    <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
    <!-- <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-container fluid class="p-2">
        <b-row v-if="inventoryGallery.length > 0">
          <b-col xs="12" sm="6" md="4" lg="3" class="mb-2" v-for="(image, index) in inventoryGallery" :key="image.item">
            <b-media-aside>
              <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton" @click="imageDelete(index)" />
              <b-img thumbnail fluid ref="refPreviewEl" @click="imageClick(baseURL + image)" :src="baseURL + image" style="cursor:zoom-in" />
            </b-media-aside>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay> -->

    <b-overlay :show="formShow" v-if="!errorPage" rounded="sm" no-fade>
      <b-row>
        <!-- images group 1 -->
        <b-col md="6">
          <b-form-file class="mb-1" placeholder="Choose a file or drop it here..." accept="image/*" @input="imageSelected" v-model="tempImage" drop-placeholder="Drop file here..." />

          <b-card title="Appraisal Photos">
            <b-row>
              <draggable :list="vehicleList" ghost-class="ghost" :group="{ name: 'websiteImages', put: true }" class="movespace cursor-move d-flex justify-content-start flex-wrap p-1">
                <b-col md="3" sm="3" cols="6" v-for="(item, index) in vehicleList" :key="index" class="mb-1">
                  <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton" v-if="!item.id" @click="imageDelete(index)" />
                  <b-img :src="baseURL + item.name" v-if="item != null" thumbnail fluid />
                </b-col>
                <h4 v-if="vehicleList == 0">No photos</h4>
              </draggable>
            </b-row>
          </b-card>
        </b-col>

        <!-- images group 2 -->
        <b-col md="6">
          <b-card title="Website Photos">
            <b-row>
              <draggable :list="websiteList" ghost-class="ghost" :group="{ name: 'websiteImages', put: true }" class="movespace cursor-move d-flex justify-content-start flex-wrap p-1">
                <b-col md="3" sm="3" cols="6" v-for="(item, index) in websiteList" :key="index" class="mb-1">
                  <b-img :src="baseURL + item.name" v-if="item != null" thumbnail @dblclick="selectImage(item.name)" :style="[item.name == selectedImageName ? 'border: 3px solid green' : '']" fluid />
                </b-col>
                <h4 v-if="websiteList.length == 0">No photos, drag and drop or upload new photo</h4>
              </draggable>
            </b-row>
            <b-row class="text-center text-danger">*Double Click to Select featured picture</b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <b-card v-if="!errorPage" no-body>
      <b-card-body>
        <div>
          <label for="textarea-default">Description</label>
          <b-form-textarea id="textarea-default" v-model="description" placeholder="Description" rows="3" />
        </div>

        <div>
          <b-form-checkbox checked="false" class="mt-2" v-model="homepagestatus" name="check-button" switch inline>
            Homepage Featured Listing
          </b-form-checkbox>
        </div>

        <b-button v-b-toggle.sidebar-send-purchase variant="success" @click="saveChanges()" class="mb-75 mt-5 text-right">
          Save Changes
        </b-button>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils';
import store from '@/store';
import { onUnmounted, watch, ref } from '@vue/composition-api';
import router from '@/router';
import imageCropper from '@core/components/image-cropper/ImageCropper.vue';
import {
  BFormFile,
  BOverlay,
  VBTooltip,
  BListGroupItem,
  BContainer,
  BCardGroup,
  BFormCheckbox,
  BFormTextarea,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BCarousel,
  BCarouselSlide,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BBadge,
  BCardHeader,
  BCardTitle,
  BMedia,
  VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import inventoryStoreModule from '../inventoryStoreModule';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import MoneyFormat from 'vue-money-format';
import draggable from 'vuedraggable';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  components: {
    // BSV
    BFormFile,
    BOverlay,
    VBTooltip,
    BListGroupItem,
    BContainer,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    VueGallerySlideshow,
    MoneyFormat,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardGroup,
    imageCropper,
    // 3rd Party
    Swiper,
    SwiperSlide,
    draggable,
  },
  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = 'inventory';

    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_APP_STORE_MODULE_NAME, inventoryStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.formShow = true;
    store
      .dispatch('inventory/fetchInventoryView', { id: router.currentRoute.params.id })
      .then((response) => {
        this.vehicleData = response.data.inventory.inventory;
        response.data.inventory.get_images.forEach((element) => {
          if (element.status == 'A') {
            this.vehicleList.push(element);
          } else if (element.status == 'B') {
            this.websiteList.push(element);
          } else if (element.status == 'C') {
            this.websiteList.push(element);
            this.selectedImageName = element.name;
          }
        });

        //this.selectedImageName = response.data.inventory.selectedImage;
        this.description = response.data.inventory.description;
        if (response.data.inventory.homepage == 1) {
          this.homepagestatus = true;
        }
        this.formShow = false;
      })
      .catch((error) => {
        this.errorPage = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            text: 'Please try again or report an issue to support',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        });
        this.formShow = false;
      });
  },

  data() {
    return {
      styleObject: {
        border: '5px',
        solid: 'green',
      },
      baseURL: store.state.app.baseURL,
      errorPage: false,
      vehicleData: null,
      selectedImageName: null,
      selectedImage: null,
      description: null,
      homepagestatus: false,

      vehicleList: [],
      websiteList: [],
      croppedImage: null,
      tempImage: null,
      formShow: false,
    };
  },

  computed: {},

  methods: {
    saveChanges() {
      this.formShow = true;

      var detailData = {
        id: this.vehicleData.id,
        description: this.description,
        selectedImageName: this.selectedImageName,
        homepagestatus: this.homepagestatus,
        vehicleImages: this.vehicleList,
        websiteImages: this.websiteList,
      };

      store
        .dispatch('inventory/updateinventorydetail', detailData)
        .then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Inventory Detail Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            setTimeout(function() {
              this.formShow = false;
              router.push({ name: 'inventory-publishedit' });
            }, 500);
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
          //router.push({ name: 'inventory-list' });
        });
    },

    selectImage(val) {
      this.selectedImageName = val;
    },

    imageSelected(event) {
      //const file = event.target.files ? event.target.files[0] : null;
      if (event != null) {
        this.getBase64(event).then((data) => {
          this.selectedImage = data;
        });
      }
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.formShow = true;
        let formData = new FormData();
        formData.append('file', croppedImage);
        store
          .dispatch('inventory/imageUpload', formData)
          .then((response) => {
            this.vehicleList.push({
              name: response.data,
            });

            this.tempImage = null;
            this.selectedImage = null;
            this.formShow = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Uploaded Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },

    imageDelete(item) {
      this.vehicleList.splice(item, 1);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    updateStatus(val) {
      var value = {
        id: router.currentRoute.params.id,
        value: val,
      };
      store
        .dispatch('inventory/updateVehicleStatus', value)
        .then((response) => {})
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.list-group-item {
  transition: all 1s;
}

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.movespace {
  border: 1px solid #bbb;
  min-height: 50px;
  min-width: 100%;
  text-align: center;
  font: 21pt bold arial;
  color: #bbb;
  // display: contents;
  display: block;
}

.deleteButton {
  position: absolute;

  top: -8px;
  right: 5px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #3178cf;
  cursor: pointer;
  opacity: 0.5;
}

.deleteButton:hover {
  opacity: 1;
}
</style>
